import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meal-selected-inner',
  templateUrl: './meal-selected-inner.component.html',
  styleUrls: ['./meal-selected-inner.component.scss'],
})
export class MealSelectedInnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
