import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-activity-main',
  templateUrl: './activity-main.component.html',
  styleUrls: ['./activity-main.component.scss'],
})
export class ActivityMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
